import React from "react";
import PropTypes from "prop-types";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";

const TopLayout = ({ children, theme }) => (
  <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
);

TopLayout.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
};

export default TopLayout;
