import React from "react";
import PropTypes from "prop-types";
import { CssBaseline } from "@material-ui/core";
import { getCurrentLangKey } from "ptz-i18n";
import { StaticQuery, graphql, withPrefix } from "gatsby";
import Head from "../head/head.js";
import Helmet from "react-helmet";
import { IntlProvider } from "react-intl";
import i18nMessages from "../../../content/messagesI18n";
import "./main.css";

const TopLayout = ({ element, data, props }) => {
  const { uri } = props;
  const {
    languages: { langs, defaultLangKey },
    author,
    description,
    siteTitle,
  } = data.site.siteMetadata;
  const langKey = getCurrentLangKey(langs, defaultLangKey, uri);

  const siteUrl = "https://cyclope.ai";
  const canonical = `${siteUrl}${withPrefix(uri)}`;
  return (
    <IntlProvider
      locale={langKey}
      messages={i18nMessages[langKey]}
      defaultLocale="fr"
    >
      <Helmet>
        <html lang={langKey} />
        <meta name="author" content={author.name} />
        {/* <meta name="description" content={description} /> */}
        {/* <meta property="og:title" content={siteTitle} /> */}
        {/* <meta property="og:description" content={description} /> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonical} />
        <meta
          property="og:image:secure_url"
          content="/icons/favicon-32x32.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={siteTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:site" content={`@${author.twitter}`} />
        <meta name="twitter:url" content={canonical} />
        <meta name="twitter:author" content={`@${author.twitter}`} />
        <meta
          name="twitter:image"
          content={`${siteUrl}${withPrefix("/icons/favicon.png")}`}
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="canonical" href={canonical} />
        <link
          rel="alternate"
          hrefLang={langKey === "fr" ? "en_EN" : "fr_FR"}
          href={canonical.replace(langKey, langKey === "fr" ? "en" : "fr")}
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Head />
      <CssBaseline />
      {element}
    </IntlProvider>
  );
};

const LayoutWithQuery = (props) => (
  <StaticQuery
    query={graphql`
      query LayoutBisQuery {
        site {
          siteMetadata {
            author {
              twitter
            }
            siteTitle
            languages {
              defaultLangKey
              langs
            }
          }
        }
        sitePage {
          path
          matchPath
        }
      }
    `}
    render={(data) => <TopLayout data={data} {...props} />}
  />
);

TopLayout.propTypes = {
  element: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};

export default LayoutWithQuery;
