import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },  
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#ccd4e6",
          borderWidth: 2,
        },
        "&:hover $notchedOutline": {
          borderColor: "#110174",
          borderWidth: 2,
        },
        "&$focused $notchedOutline": {
          borderColor: "#110174",
          borderWidth: 2,
        },
      },
    },
    MuiNativeSelect: {
      outlined: {
        border: "solid 1px red",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "3px",
        fontWeight: 600,
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      containedSizeLarge: {
        padding: "16px",
        minWidth: "180px",
        fontSize: "1.1rem",
      },
      outlinedSizeLarge: {
        padding: "16px",
        minWidth: "180px",
        fontSize: "1.1rem",
      },
      contained: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      containedSecondary: {
        backgroundColor: "#fff",
        color: "#110174",
        "&:hover": {
          color: "#3562FF",
          backgroundColor: "#fff",
        },
      },
      textPrimary: {
        color: "#110174",
        "&:hover": {
          backgroundColor: "transparent",
          color: "#3562FF",
        },
      },
      containedPrimary: {
        backgroundColor: "#110174",
        "&:hover": {
          backgroundColor: "#3562FF",
        },
      },
      outlinedPrimary: {
        color: "#110174",
        border: "solid 1px #110174",
        backgroundColor: "#fff",
        "&:hover": {
          borderColor: "#3562FF",
          color: "#3562FF",
          backgroundColor: "#fff",
        },
      },
    },
    MuiToolbar: {
      regular: {
        "@media (min-width:600px) and (orientation: landscape)": {
          minHeight: 58,
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#18D1A7",
      main: "#110174",
      dark: "#110174",
    },
    secondary: {
      main: "#110174",
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: "#596E80",
      secondary: "#14142A",
      shine: "#377DFF",
    },
    custom: {
      additional1: "#E5F1FF",
      additional2: "#EAF3F3",
      additional3: "#d4d4d4",
      additional4: "#1F3598",
    },
    background: {
      default: "#FFF",
      paper: "#EDEEF6",
      medium: "#F5F6FA",
    },
  },
  typography: {
    fontFamily: ["Inter", "Roboto", "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    h1: {
      fontWeight: "bold",
      color: "#14142A",
      fontSize: "2rem",
      marginBottom: "0.5rem",
      "@media (min-width:960px) and (orientation: landscape)": {
        fontSize: "4rem",
        marginBottom: "1.5rem",
      },
      "@media (min-width:600px) and (orientation: landscape)": {
        fontSize: "3rem",
        marginBottom: "1rem",
      },
    },
    h2: {
      fontSize: "3.75rem",
      color: "#14142A",
    },
    h3: {
      fontSize: "2rem",
      color: "#14142A",
      "@media (min-width:600px) and (orientation: landscape)": {
        fontSize: "2.5rem",
      },
      "@media (min-width:960px) and (orientation: landscape)": {
        fontSize: "3rem",
      },
    },
    h4: {
      fontSize: "1.5rem",
      color: "#14142A",
      "@media (min-width:600px) and (orientation: landscape)": {
        fontSize: "1.9rem",
      },
      "@media (min-width:960px) and (orientation: landscape)": {
        fontSize: "2rem",
      },
    },
    h5: {
      color: "#14142A",
      fontSize: "1.5rem",
    },
    h6: {
      color: "#14142A",
    },
    body1: {
      margin: "16px 0",
    },
    body2: {
      margin: "16px 0",
    },
  },
  layout: {
    paddingRegular: "5% 10%",
    paddingLeftRegular: "10%",
    paddingFirstRow: "7% 10% 5% 10%",
    paddingLastRow: "5% 10% 5% 10%",
    paddingRegularMobile: "7% 5%",
    paddingLastRowMobile: "5% 16px 10% 16px",
  },
});

export default theme;
