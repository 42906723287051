import wrapWithLayout from "./src/components/layout/layout_base";

function getCookie(name) {
  var dc = document.cookie;
  var prefix = name + "=";
  var begin = dc.indexOf("; " + prefix);
  if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin != 0) return null;
  }
  else
  {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end == -1) {
      end = dc.length;
      }
  }
  // because unescape has been deprecated, replaced with decodeURI
  //return unescape(dc.substring(begin + prefix.length, end));
  return decodeURI(dc.substring(begin + prefix.length, end));
} 

export const onClientEntry = () => {
  if (window.location.pathname === "/") {
    const userLang = navigator.language.substr(0, 2).toLowerCase() === "fr" ? "fr" : "en";
    window.location.pathname = `/${userLang}/`;
  }
};

export const wrapPageElement = wrapWithLayout;
